// Use this to write your custom SCSS
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    z-index: 997;
    transition: all 0.5s;
    background: #Ffefefe;
    padding: 20px 0;
    position: fixed;
  }

  #header.header-scrolled {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding: 5px 0;
    background: #fff;

  }

  #header .logo h1 {
    font-size: 30px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 1px;
  }

  #header .logo h1 a, #header .logo h1 a:hover {
    color: #222222;
    text-decoration: none;
  }

  #header .logo img {
    padding: 0;
    margin: 0;
    max-height: 20px;
  }

  body { margin: 0; }

// Progress Bar

    .progress { position: fixed; z-index: 998; top: 0px; left: 0; height: 2px; background-color:  #3eb0ef;
      ; transition: all linear 0.1s; min-width: 0%; }
/*
      .content { width: 100%; height: 100vh; margin-bottom: 5px; justify-content: center; align-items: center; }

      .content-footer { width: 100%; height: 274px; margin-bottom: 5px; justify-content: center; align-items: center; }
*/
