.showcase {
  //  background: url('/images/showcase.png');
  //  background-size: cover;
    padding: 125px 0 184px;

   @include medium-up {
      padding: 142px 0 219px;
   }


    & .flex {
        flex-direction: column;
        align-items: flex-start;

        & h1 {
            color: #333;
            margin-bottom: 18px;

            @include medium-up {
                width: 60%;
                margin-bottom: 27px;
            }
        }

        p {
            color: #333;
            font-size: $font-size-6;
            line-height: $line-height-sm;
            margin-bottom: 48px;
            font-weight: $font-weight-medium;

            @include medium-up {
                width: 60%;
                margin-bottom: 32px;
                font-size: $font-size-5;
                line-height: $line-height-base;
            }
        }
    }
}

.company {
    padding: 36px 0;

    @include medium-up {
        padding: 73px 0;
    }

    & .grid {
        grid-template-columns: 1fr;
        gap: 36px;

        @include medium-up {
            grid-template-columns: 55% 45%;
            gap: unset;
        }
    }

    &__content {
        align-self: flex-start;

        &-paragraph {
            @include font-15;
            margin: 22px 0 16px;

            @include medium-up {
                @include font-18;
                margin: 45px 0 4px;
                width: 90%;
            }
        }

        &-item {
            display: flex;
            align-items: center;
            margin-top: 32px;

            @include medium-up {
                width: 70%;
                margin-top: 50px;
            }

            & img {
                height: 100%;
                margin-right: 19px;
            }

            &-label {
                display: block;
                margin-bottom: 6px;
                font-weight: $font-weight-bold;
                @include font-18;
            }

            p {
                @include font-15;
            }
        }

        a {
            margin-top: 36px;
            @include medium-up {
                margin-top: 54px;
            }
        }

        h2 {
            width: 50%;

            @include medium-up {
                width: 100%;
            }
        }
    }

    &__about {
        display: block;
        padding: 52px 15px;
        min-height: 284px;
        background: url('/images/about-1.png');
        color: $white;

        @include medium-up {
            display: none;
        }
    }
}

.about {
    background: url('/images/about-background.png');
    background-size: cover;
    color: $white;
    margin-top: 150px;
    padding-bottom: 38px;

    @include medium-up {
        padding-bottom: 114px;
    }

    &__top {
        min-height: 284px;
        padding: 52px 15px;
        margin-top: -142px;

        @include medium-up {
            padding: 52px;
        }

        &--left {
            display: none;

            @include medium-up {
                display: block;
                background: url('/images/about-1.png');
            }
        }

        &--right {
            background: url('/images/about-2.png');
        }

        &-number {
            font-size: $font-size-1;
            line-height: 64px;
            font-weight: $font-weight-bold;
        }

        &-heading {
            @include font-18;
            font-weight: $font-weight-bold;
            margin: 11px 0 6px;
        }

        &-content {
            @include font-15;

            @include medium-up {
                width: 75%;
            }
        }
    }

    &__grid {
        &--top {
            @include medium-up {
                gap: 30px;
            }
        }

        &--bottom {
            margin-top: 42px;
            grid-template-columns: 1fr;

            @include medium-up {
                margin-top: 92px;
                grid-template-columns: repeat(2, 1fr);
            }

            @include large-up {
                grid-template-columns: repeat(4, 1fr);
            }
        }

        &-item {
            position: relative;
            background-color: $white;
            padding: 40px 81px 40px 80px;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-bottom-right-radius: 20px;

            @include medium-up {
                padding: 46px 27px 46px 28px;
            }

            &:nth-child(2) {
                padding: 40px 79px 40px 79px;

                @include medium-up {
                    padding: 46px 24px 46px 25px;
                }
            }

            &-label {
                color: $blue-primary;
                font-weight: $font-weight-bold;
                text-align: center;

                @include font-18;
            }

            & p {
                color: $gray-dark;
                text-align: center;
                margin: 6px 0 44px;

                @include font-15;
            }

            &-btn {
                position: absolute;
                bottom: 0;
                right: 0;
                background-color: $gray-dark;
                cursor: pointer;
                height: 64px;
                width: 64px;
                border-bottom-right-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &-icon {
                margin-bottom: 31px;
            }
        }
    }


    &__heading {
        text-align: center;
        margin-top: 42px;

        @include medium-up {
            margin-top: 91px;
        }
    }
}

.blog {
    padding: 36px 0;

    @include medium-up {
        padding: 74px 0;

    }

    &__heading {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    & .grid {
        margin-top: 22px;
        gap: 42px;

        @include medium-up {
            margin-top: 79px;
            grid-template-columns: repeat(3, 1fr);
            gap: 38px;
        }
    }

    &__article {
        display: flex;
        flex-direction: column;

        &-date {
            display: block;
            font-weight: $font-weight-bold;
            border-bottom: 1px solid $gray-light;
            width: 30%;
            color: $blue-primary;
            margin: 16px 0 12px;
            @include font-18;
        }

        &-info {
            display: flex;

            & span {
                display: flex;
                align-items: center;
                margin-right: 20px;

                & img {
                    margin-right: 8px;
                }
            }
        }

        & h3 {
            margin: 18px 0 8px;
        }

        & p {
            margin-bottom: 18px;
            @include font-15;
        }

        & a {
            display: flex;
            align-items: center;
            color: $gray-dark;
            font-weight: $font-weight-bold;

            & img {
                margin-left: 8px;
            }
        }
    }
}

.contact {
    background-color: $blue-primary;
    padding: 62px 0 64px;

    @include medium-up {
        padding: 87px 0;
    }

    & .grid {
        grid-template-columns: 1fr;
        text-align: center;

        @include medium-up {
            grid-template-columns: 70% 30%;
            text-align: left;
        }
    }

    &__heading {
        font-size: 23px;
        line-height: $line-height-base;
        font-weight: $font-weight-bold;
        color: $white;

        @include medium-up {
            font-size: 42px;
            line-height: 51px;
        }
    }

    &__cta {
        justify-self: center;

        @include medium-up {
            justify-self: flex-end;
        }
    }
}
