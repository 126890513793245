
#main
{

  background: #fefefe;


  @include medium-up {
    z-index: 1;
    height: 1800px;
    min-height: 1500px;
    margin-bottom: 275px;
    }
}

.container {
    max-width: 1300px;
   margin: 0 auto;
    padding: 0 $grid-base * 1.5;
    overflow: visible;


      &--full {
          max-width: 1440px;
      }
}

// GRID LAYOUT CLASSES
.grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    justify-content: center;
    align-items: center;
    height: 100%;

    @include medium-up {
        grid-template-columns: repeat(2, 1fr);
    }

    &--3 {
        grid-template-columns: repeat(3, 1fr);
    }

    &--4 {
        grid-template-columns: repeat(4, 1fr);
    }
}

// FLEXBOX LAYOUT CLASSES

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.col {
    width: 100%;
    padding: $grid-base * 1.5;
    display: inline-block;

    &--md25 {
        @include medium-up {
            flex: 0 0 25%;
            max-width: 25%;
        }
    }

    &--md33 {
        @include medium-up {
            flex: 0 0 33.3333%;
            max-width: 33.3333%;
        }
    }

    &--md50 {
        @include medium-up {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    &--lg25 {
        @include large-up {
            flex: 0 0 25%;
            max-width: 25%;
        }
    }

    &--lg33 {
        @include large-up {
            flex: 0 0 33.3333%;
            max-width: 33.3333%;
        }
    }

    &--lg50 {
        @include large-up {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    &--lg75 {
        @include large-up {
            flex: 0 0 75%;
            max-width: 75%;
        }
    }

    &--hidden-sm {
        display: none;

        @include medium-up {
            display: inline-block;
        }
    }
}
