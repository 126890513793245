// FLEX
.u-display-flex {
    display: flex;
}
.u-justify-center {
    justify-content: center;
}
.u-justify-end {
    justify-content: flex-end;
}
.u-justify-space {
    justify-content: space-between;
}
.u-justify-self-center {
    justify-self: center;
}
.u-justify-self-end {
    justify-self: flex-end;
}
.u-align-center {
    align-items: center;
}
.u-align-end {
    align-items: flex-end;
}
.u-flex-direction-col {
    flex-direction: column;
}
.u-flex-direction-row {
    flex-direction: row;
}

// TEXT
.u-text-center {
    text-align: center;
}
.u-font-weight-bold {
  font-weight: bold;
}
.u-cursor-pointer {
  cursor: pointer;
}

// COLORS 

.u-text-white {
    color: $white;
}

.u-text-dark {
    color: $gray-dark;
}

// SPACING
.u-m-auto {
    margin: 0 auto;
}

.u-mt-5 {
    margin-top: $grid-base * 0.5;
}
.u-mt-10 {
    margin-top: $grid-base;
}
.u-mt-15 {
    margin-top: $grid-base * 1.5;
}
.u-mt-20 {
    margin-top: $grid-base * 2;
}
.u-mt-30 {
    margin-top: $grid-base * 3;
}
.u-mt-40 {
    margin-top: $grid-base * 4;
}
.u-mt-50 {
    margin-top: $grid-base * 5;
}
.u-mb-5 {
    margin-bottom: $grid-base * 0.5;
}
.u-mb-10 {
    margin-bottom: $grid-base;
}
.u-mb-15 {
    margin-bottom: $grid-base * 1.5;
}
.u-mb-20 {
    margin-bottom: $grid-base * 2;
}
.u-mb-30 {
    margin-bottom: $grid-base * 3;
}
.u-mb-40 {
    margin-bottom: $grid-base * 4;
}
.u-mb-50 {
    margin-bottom: $grid-base * 5;
}
.u-mr-5 {
    margin-right: $grid-base * 0.5;
}
.u-mr-10 {
    margin-right: $grid-base;
}
.u-mr-15 {
    margin-right: $grid-base * 1.5;
}
.u-mr-20 {
    margin-right: $grid-base * 2;
}
.u-mr-30 {
    margin-right: $grid-base * 3;
}
.u-mr-40 {
    margin-right: $grid-base * 4;
}
.u-mr-50 {
    margin-right: $grid-base * 5;
}
.u-ml-5 {
    margin-left: $grid-base * 0.5;
}
.u-ml-10 {
    margin-left: $grid-base;
}
.u-ml-15 {
    margin-left: $grid-base * 1.5;
}
.u-ml-20 {
    margin-left: $grid-base * 2;
}
.u-ml-30 {
    margin-left: $grid-base * 3;
}
.u-ml-40 {
    margin-left: $grid-base * 4;
}
.u-ml-50 {
    margin-left: $grid-base * 5;
}

.u-pt-5 {
    padding-top: $grid-base * 0.5;
}
.u-pt-10 {
    padding-top: $grid-base;
}
.u-pt-15 {
    padding-top: $grid-base * 1.5;
}
.u-pt-20 {
    padding-top: $grid-base * 2;
}

.u-pb-5 {
    padding-bottom: $grid-base * 0.5;
}
.u-pb-10 {
    padding-bottom: $grid-base;
}
.u-pb-15 {
    padding-bottom: $grid-base * 1.5;
}
.u-pb-20 {
    padding-bottom: $grid-base * 2;
}

.u-pr-5 {
    padding-right: $grid-base * 0.5;
}
.u-pr-10 {
    padding-right: $grid-base;
}
.u-pr-15 {
    padding-right: $grid-base * 1.5;
}
.u-pr-20 {
    padding-right: $grid-base * 2;
}

.u-pl-5 {
    padding-left: $grid-base * 0.5;
}
.u-pl-10 {
    padding-left: $grid-base;
}
.u-pl-15 {
    padding-left: $grid-base * 1.5;
}
.u-pl-20 {
    padding-left: $grid-base * 2;
}

.u-position-relative {
    position: relative;
}

// overlay
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0, 16, 61, 0.84);
}

.hidden {
    opacity: 0;
    visibility: hidden;
    transition: .25s ease-in-out;
}

.visible {
    opacity: 1;
    visibility: visible;
    transition: .25s ease-in-out;
}

.none {
    &.btn-menu {
        display: none;
    }
}

.block {
    &.btn-menu {
        display: inline-block;

        @include medium-up {
            display: none;
        }
    }
}

.u-img {
    justify-self: center;

    &--desktop {
        display: none;
        @extend .u-img;

        @include medium-up {
            display: inline-block;
        }
    }

    &--mobile {
        display: inline-block;

        @extend .u-img;
        @include medium-up {
            display: none;
        }
    }
}