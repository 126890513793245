.header {
    width: 100%;
    padding: 8px 0 5px;
    background-color: $white;
    position: fixed;


    & .flex {
        justify-content: space-between;
    }

    &__btn {
        &.btn {
            display: none;

            @include medium-up {
                display: inline-block;
            }
        }
    }
}
