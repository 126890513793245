*, *::before, *::after {
    box-sizing: border-box;
    padding:  0;
    margin: 0;
}

body {
    color: $gray-dark;
    background-color: $grey;
    font-family: 'Montserrat', sans-serif;
    font-size: $font-size-base;
    margin: 0;
    padding: 0;
  /*  height: 100%; */
    position: relative;
    overflow-x: hidden;
   /* z-index: 2;
        margin-bottom: 175px; */
}

img {
    max-width: 100%;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    & li {
        font-size: $font-size-base;
    }
}

a, li {
    text-decoration: none;

    &:focus {
        outline: 1px solid transparent;
    }
}
