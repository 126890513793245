//font 
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap');
@import 'base/variables';
@import 'base/colors';
@import 'base/mixins';
@import 'base/utils';
@import 'base/reset';
@import 'base/typography';

@import 'layout/layout';
@import 'layout/header';
@import 'layout/section';
@import 'layout/footer';

@import 'components/button';
@import 'components/navigation';


@import 'components/lightgallery';
@import 'components/style';
@import 'components/user';