// Fonts
$font-size-base: 1rem;
$font-size-1: $font-size-base * 3.25; // ~52px
$font-size-2: $font-size-base * 2.75; // ~44px
$font-size-3: $font-size-base * 1.75; // ~28px
$font-size-4: $font-size-base * 1.375; // ~22px
$font-size-5: $font-size-base * 1.125; // ~18px
$font-size-6: $font-size-base * .9375; // ~15px
$font-size-7: $font-size-base * .75; // ~12px

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;


$line-height-base: 28px;
$line-height-sm: 24px;
$line-height-lg: 34px;

// Grid
$grid-base: 10px;

// Radius
$border-radius: $grid-base * .2;

$box-shadow-dark: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
$box-shadow-light: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);

// Breakpoints
$bp-small: '536px';
$bp-medium: '992px';
$bp-large: '1280px';
